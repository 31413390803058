@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "./constants.scss";
@import "./fonts.scss";

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    outline: 0 !important;
}

body {
    margin: 0;
    font-family: "Work Sans", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0 !important;
    background-color: $purple;
    overflow-y: scroll !important;
}

#root {
    background-color: #f0f2f7;
}

ul {
    // list-style-image: url(../images/tick.png);
    list-style-position: outside;
    margin-left: 19px;
}

a {
    color: $blue_dark;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

.alert {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 0;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.DateInput_input__small {
    height: 36px;
}

.navbar {
    position: static !important;
}

div.SingleDatePicker_picker {
    z-index: 10;
}

div.SingleDatePicker {
    //width: 100%;

    div[class^="SingleDatePickerInput"],
    div[class^="DateInput"] {
        padding: 0px;
        border: 0;
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        justify-content: start;
        gap: 10px;

        & input {
            background-color: #f0f2f7;
            padding: 10px 20px;
            border-radius: 10px;
            text-align: center;
            color: black;
            font-weight: 400;
        }

        @media screen and (max-width: 400px) {
            grid-auto-flow: row;
            grid-template-columns: 100%;
            justify-items: center;
            gap: 0;
        }
    }

    & + .form_label {
        left: 20px;
        font-size: 18px;
        top: 0px;
    }
}

.SingleDatePickerInput_calendarIcon {
    padding: 0 !important;
    margin: 0 !important;
    display: flex !important;
}

.DateInput__small {
    width: 127px !important;

    @media screen and (max-width: 400px) {
        width: 100% !important;
    }
}

.DateInput_input__small {
    font-size: 12px !important;
    line-height: 14px !important;
    border: 0 !important;
    height: 38px;
}

.modal-footer {
    border-top: 0;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 20px;
    gap: 40px;
}

.modal-header {
    border-bottom: 0;
    padding-bottom: 0;
    padding-top: 18px;
    padding-right: 20px;
}

.modal-body {
    padding: 0 40px;

    @media screen and (max-width: 500px) {
        padding: 0 20px;
    }
}

.modal-content {
    padding: 0px;
    border-radius: 1.3rem;
}

.modal-dialog {
    max-width: 800px !important;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #f2bb37;
    background-color: #f2bb37;
}

.carousel-container {
    max-width: 800px;
    width: 100%;
}

.react-multi-carousel-track {
    align-items: center;
}

.react-multiple-carousel__arrow {
    background: transparent !important;

    &::before,
    &::after {
        color: #959ac5 !important;
        font-weight: bold;
    }
}

.react-multiple-carousel__arrow--left {
    left: 0% !important;
}

.react-multiple-carousel__arrow--right {
    right: 0% !important;
}

.react-multi-carousel-item > div {
    text-align: center;
}

.SingleDatePickerInput_clearDate {
    top: calc(50% - 2px) !important;
    margin: 0 0 0 5px !important;

    &:hover {
        background-color: transparent !important;
    }
}

@media screen and (max-width: 400px) {
    .SingleDatePicker {
        width: 46%;
    }

    .form-group {
        .SingleDatePicker {
            max-width: 45%;
        }

        .SingleDatePickerInput_clearDate__small {
            padding: 6px 2px;
        }

        div.SingleDatePicker div[class^="SingleDatePickerInput"] input,
        div.SingleDatePicker div[class^="DateInput"] input {
            padding: 8px 16px 8px 8px;
        }
    }
}

@media screen and (min-width: 1920px) {
    .carousel-container {
        max-width: 1200px;
        width: 100%;
    }
}

.seal {
    transition: all 0.4s ease;
}

.disable {
    position: relative;
    z-index: 9999;
    width: 70%;
    margin: 0 auto;
    height: 1000px;
    transform: scale(0.8);
    transition: all 0.4s ease;
    background-color: #f3f3fb;

    @media screen and(max-width: 1100px) {
        transform: scale(0.4);
        top: -100px;
    }
}
